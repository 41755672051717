<template>
  <div id="app">

    <Head />
    <div class="main">
      <router-view @footer="footer" />
    </div>
    <Foot v-if="showFooter" />
  </div>
</template>
<script>
import Head from "@/components/header";
import Foot from "@/components/footer";

export default {
  name: "App",
  components: {
    Head,
    Foot,
  },
  data() {
    return {
      showFooter: true,
    }
  },
  methods: {
    footer(status) {
      this.showFooter = status
    },
  }
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
html,
body,
#app {
  height: 100%;
  width: 100%;
  /* margin: 0 auto;
  max-width: 1200px; */
  display: flex;
  min-width: 1200px;
  background-color: #f5f5f5;
  flex-direction: column;
  justify-content: space-between;
}
.main {
  flex: 1;
  width: 1400px;
  margin: 0 auto;
}
.container {
  margin: 10px 0;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.wrap {
  display: block;
  max-width: 1200px;
  margin: 0 auto;
}
img {
  position: relative;
  display: block;
  width: 100%;
}

.flex_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex_wrap {
  display: flex;
  flex-wrap: wrap;
}

.row {
  display: flex;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 只显示2行,超出省略号 */
.twoLines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* autoprefixer:off */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.active {
  color: #f56c6c;
  transform: scale(1.2);
  font-weight: bold;
}

.goodsList {
  width: 23%;
  margin: 1%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  justify-content: space-between;
  transition: 0.4s; //过渡效果

  .column {
    padding: 10px;
    flex: 1;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.goodsList:hover {
  cursor: pointer;
  transform: scale(1.02);
}
</style>
