<template>
  <div class="sticky">
    <div class="header">
      <el-row type="flex" :gutter="20" justify="center" align="middle">
        <el-col :span="8">
          <router-link :to="{ path: 'home', query: { current: 0 } }" class="flex_row" style="justify-content:center;text-decoration:none;color:#000">
            <img style="width: 60px; height: 60px" src="@/assets/logo.png" />
            <div style="margin-left:10px">
              <div style="font-weight:bold;font-size: 20px;">七手游</div>
              <div style="margin-top:10px">专业有保障的游戏交易平台</div>
            </div>
          </router-link>
        </el-col>

        <el-col :span="12">
          <div class="border flex_row">
            <el-dropdown trigger="click" style="cursor: pointer;width: 180px;text-align: center;">
              <span>
                {{ gameName || '选择游戏' }} <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in gameList" :key="index">
                  <p @click="gameSelect(item)" style="padding: 10px;">{{ item.name }}</p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <!-- <el-col :span="3">
                <el-dropdown trigger="click" style="cursor: pointer;">
                  <span>
                    {{ shopName || '客户端' }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item, index) in clientList" :key="index">
                      <span @click="ShopSele(item)"> {{ item.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
              <el-col :span="3">
                <el-dropdown trigger="click" style="cursor: pointer;">
                  <span>
                    {{ areaName || '区服' }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in areaList" :key="item.id">
                      <span @click="selectArea(item)" style="padding: 10px;">{{ item.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col> -->
            <!-- <div class="flex_row"> -->
            <el-select v-model="inputGame" filterable @change="selectGames" remote reserve-keyword placeholder="请输入游戏" value-key="id" :remote-method="remoteMethod" :loading="loading" clearable style="flex:1;margin-left: 20px;">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item">
              </el-option>
            </el-select>
            <!-- <el-button type="danger" size="small" style="margin-left:10px" @click="toSearch()">搜索</el-button> -->
            <!-- </div> -->
          </div>
        </el-col>
        <el-col :span="4">
          <img @click="toH5" src="@/assets/h5.png" mode="widthFix" style="width:150px" class="h5">
        </el-col>
      </el-row>
    </div>

    <div class="row tabMenu">
      <div class="tabs" v-for="(item, index) in tabs" :key="index" @click="tabChange(index, item.path)" :class="current == index ? 'active-boxs' : ''">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { selectGameList, selectGameSecondAreaList, selectGameFirstAreaList, searchGameByName } from '@/config/api'
export default {
  data() {
    return {
      current: 0,
      gameName: '',
      // gameid: '',
      // areaName: '',
      // shopName: '',
      tabs: [
        { name: '首页', path: '/home' },
        { name: '我要买', path: '/buy' },
        { name: '我要卖', path: '/sale' },
        { name: '交易须知', path: '/explain' },
      ],
      gameList: [],
      options: [],
      clientList: [
        { name: '请先选择游戏' },
      ],
      areaList: [{
        name: '请先选择游戏和客户端'
      }],
      loading: false,
      inputGame: '',
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        // console.log(newVal);
        if (newVal.query.current) {
          this.current = newVal.query.current
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.getGames()
  },
  methods: {
    // 选择游戏数据
    getGames() {
      selectGameList().then(res => {
        this.gameList = res
      })
    },
    // 点击选择游戏
    gameSelect(item) {
      console.log(item);
      // this.gameid = item.id
      this.gameName = item.name
      this.$router.push({
        path: 'details',
        query: {
          gameName: item.name,
          id: item.id,
          current: 1,
        }
      })
      this.inputGame = ''
    },
    // 客户端
    // getselectGameFirstAreaList() {
    //   selectGameFirstAreaList({ gameid: this.gameid }).then(res => {
    //     this.clientList = res
    //   })
    // },
    // 点击选择客户端
    // ShopSele(item) {
    //   console.log(item);
    //   if (!item.id) {
    //     this.$message.error('请先选择游戏');
    //     return
    //   }
    //   this.shopName = item.name
    //   this.getselectGameSecondAreaList(item.id)
    // },
    // // 区服
    // getselectGameSecondAreaList(pid) {
    //   selectGameSecondAreaList({ pid: pid }
    //   ).then(res => {
    //     this.areaList = res
    //   })
    // },
    // 点击选择区服
    // selectArea(item) {
    //   if (!item.id) {
    //     this.$message.error('请先选择游戏和客户端');
    //     return
    //   }
    //   this.areaName = item.name
    //   this.$router.push({
    //     name: 'details',
    //     query: { id: item.id }
    //   })
    // },
    selectGames(item) {
      console.log(item)
      this.$router.push({
        path: 'details',
        query: {
          gameName: item.name,
          id: item.id,
          current: 1,
        }
      })
      this.gameName = ''
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        searchGameByName({ name: query }).then(res => {
          this.options = res
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    },
    tabChange(index, path) {
      // console.log(index);
      if (this.current != index) {
        this.current = index
        this.$router.push({
          path: path,
          query: {
            current: index
          }
        })
      }
    },
    // 跳转到h5
    toH5() {
      window.open("https://www.iqywl.cn", "_blank");
    },
  },
};
</script>

<style lang="scss">
// .sticky {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 99999;
// }
.header {
  padding: 20px 0;
  width: 100%;
  overflow-x: hidden;
  background-color: #fff;
}

.border {
  border: 1px solid #f56c6c;
  padding: 10px 20px 10px 0;
  border-radius: 5px;
}
.h5:hover,
.tabs:hover {
  cursor: pointer;
}

.tabMenu {
  margin: 10px 0;
  background-color: #fff;
  justify-content: space-around;
  border-bottom: 4px solid transparent;
}

.tabs {
  padding: 20px;
  text-align: center;
}

.active-boxs {
  color: #f56c6c;
  font-weight: bold;
  border-bottom: 4px solid #f56c6c;
}

.el-dropdown-menu {
  display: flex;
  flex-wrap: wrap;
  top: 80px !important;
  max-height: 350px;
  right: 200px;
  left: 450px !important;
  overflow-y: auto;
}
</style>