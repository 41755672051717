<template>
  <div class="footer">
    <div style="position:relative" v-if="showFooter">
      <img src="@/assets/ad1.png" style="margin:20px 0" />
      <i class="el-icon-close" @click="showFooter=false" />
    </div>
    <img src="@/assets/ad2.png" />
    <div class="footer-wrap-box">
      <div class="footer-row flex_wrap">
        <div v-for="item in helpList" :key="item.id" :style="`width:calc(100% / ${helpList.length+1});text-align:center`">
          <div class="label">{{item.name}}</div>
          <div v-for="child in item.children" :key="child.id" style="font-size: 14px;" class="nowrap">
            <router-link :to="{name:'explain',query:{name:child.name,id:child.id,current:3}}" style="color:#fff">
              {{child.name}}
            </router-link>
          </div>
        </div>
        <div style="text-align:center;font-size: 14px;" class="nowrap">
          <div class="label">关于我们</div>
          <div>
            <router-link :to="{name:'explain',query:{name:'隐私政策',current:3}}" style="color:#fff">
              隐私政策
            </router-link>
          </div>
          <div>
            <router-link :to="{name:'explain',query:{name:'用户协议',current:3}}" style="color:#fff">
              用户协议
            </router-link>
          </div>
        </div>

      </div>
    </div>
    <div class="bottom">
      <p>© 2023 至今 iqywl.cn 版权所有    © Since 2023 iqywl.cn. All Rights Reserved.</p>
      <p class="row" style="justify-content: center;">
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank" style="color:#7f7d7e">豫ICP备2023018623号</a>&nbsp;| 增值电信业务经营许可证：豫B2-20231029 |
        <a class="row" href="https://beian.mps.gov.cn/#/query/webSearch?code=41140002000703" target="_blank" style="color:#7f7d7e">
          <img src="@/assets/police.png" style="width: 15px;margin:0 5px" alt="" />豫公网安备41140002000703号
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import { getHelpList } from '@/config/api'

export default {
  props: {
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      helpList: [],
    }
  },
  async created() {
    let res = await getHelpList()
    this.helpList = res
  },
}

</script>
<style lang="scss">
.footer {
  width: 100%;
  .footer-wrap-box {
    width: 100%;
    background-color: #202932;
    padding: 20px 0;
    color: #fff;
  }
  .footer-row {
    width: 100%;
    margin: 0 auto;
    line-height: 2;
  }
  .el-icon-close {
    position: absolute;
    right: 10%;
    top: 50%;
    font-size: 40px;
    transform: translate(-50%, -50%);
  }
}

.label {
  font-weight: bold;
  font-size: 20px;
}
.text {
  font-size: 13px !important;
  color: #fff !important;
}
.bottom {
  background-color: #202932;
  width: 100%;
  border-top: 1px solid #888;
  color: #7f7d7e;
  padding: 10px 0;
  line-height: 2;
  font-size: 14px;
  text-align: center;
}
</style>