import request from './request'

// 获取首页轮播图
export function getSwiper() {
  return request({
    url: '/home/selectShufflingList?type=3',
    method: 'get',
  })
}
// 获取游戏
export function getTabsGames() {
  return request({
    url: `/shop/selectHomeGameList`,
    method: 'get',
  })
}
// 查询游戏
export function searchGameByName(params) {
  return request({
    url: `/shop/selectGameListByName`,
    method: 'get',
    params: params
  })
}
// 商品列表
export function getselectShopListByGame(params) {
  return request({
    url: `/shop/selectShopListByGame`,
    method: 'get',
    params: params
  })
}
// 获取客户端，手游、端游
export function getGameMenu(params) {
  return request({
    url: `/shop/selectGameTypeList`,
    method: 'get',
    params: params
  })
}
// 商品详情(相关商品列表)
export function selectOtherShopList(params) {
  return request({
    url: `/shop/selectOtherShopList`,
    method: 'get',
    params: params
  })
}
// 商品详情
export function selectShopById(params) {
  return request({
    url: `/shop/selectShopById`,
    method: 'get',
    params: params
  })
}
// 全部游戏
export function selectGameList(params) {
  return request({
    url: `/shop/selectGameList`,
    method: 'get',
    params: params
  })
}
// 首页(商品列表)
export function selectHomeShopList(params) {
  return request({
    url: `/shop/selectHomeShopList`,
    method: 'get',
    params: params
  })
}
// 搜索(操作系统列表)
export function selectShopTypeList(params) {
  return request({
    url: `/shop/selectShopTypeList`,
    method: 'get',
    params: params
  })
}
// 商品添加(选择游戏区服一级列表)
export function selectGameFirstAreaList(params) {
  return request({
    url: `/shop/selectGameFirstAreaList`,
    method: 'get',
    params: params
  })
}
// 商品添加(选择游戏区服二级列表)
export function selectGameSecondAreaList(params) {
  return request({
    url: `/shop/selectGameSecondAreaList`,
    method: 'get',
    params: params
  })
}

// 帮助中心
export function getHelpList(params) {
  return request({
    url: `/home/selectHelpList`,
    method: 'get',
    params: params
  })
}

// 帮助中心详情
export function getHelpDetaiById(params) {
  return request({
    url: `/home/selectHelpListById`,
    method: 'get',
    params: params
  })
}
// 获取公共信息
export function getPublic(params) {
  return request({
    url: `/login/selectAppCommon`,
    method: 'get',
    params: params
  })
}