import Vue from 'vue'
import App from './App.vue'
import router from "./router";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

Vue.config.productionTip = false
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
new Vue({
  el: "#app",
  router,
  render: h => h(App),
}).$mount('#app')
