import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    { path: "/", redirect: "/home" },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home")
    },
    {
      path: "/buy",
      name: "buy",
      meta: ["我要买"],
      component: () => import("@/views/buy")
    },
    {
      path: "/sale",
      name: "sale",
      meta: ["我要卖"],
      component: () => import("@/views/sale")
    },
    {
      path: "/explain",
      name: "explain",
      meta: ["交易须知"],
      component: () => import("@/views/explain")
    },
    {
      path: "/details",
      name: "details",
      meta: ["我要买"],
      component: () => import("@/views/details")
    },
    {
      path: "/alone",
      name: "alone",
      meta: ["我要买"],
      component: () => import("@/views/alone")
    },
    {
      path: "/app",
      name: "app",
      meta: ["我要买"],
      component: () => import("@/views/app")
    },
    { path: "*", redirect: { name: "index" } }
  ]
});
